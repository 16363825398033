a.app-card {
  color: #fafafa;
  text-decoration: none;
  margin: 20px;
}

.app-card > .card {
  width: 200px;
  height: 200px;
}

.app-card > .card:hover {
  cursor: pointer;
}

.app-card > .card > .name {
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: rgba(0, 0, 0, .5);
  margin-top: -3px;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.app-card > .card > .icon {
  text-align: center;
}

.app-card > .card > .icon > svg {
  width: 150px;
  height: 150px;
}
